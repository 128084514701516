"use client";

import type { Site } from "./models/site.types";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";
export const SiteContext = createContext<undefined | UseSite>(undefined);
export function useSite(): UseSite {
  const site = useContext(SiteContext);
  if (site === undefined) {
    // If you reach this statement, you have discovered a programming error :)
    throw new Error("A SiteProvider must exist in the component hierarchy.");
  }
  return site;
}
type UseSite = Site;
type SiteProviderProps = {
  readonly site: Site;
  readonly children: ReactNode;
};
export function SiteProvider({
  children,
  site
}: SiteProviderProps) {
  return <SiteContext.Provider value={site} data-sentry-element="unknown" data-sentry-component="SiteProvider" data-sentry-source-file="site.context.tsx">{children}</SiteContext.Provider>;
}