import type { TrackingDataModel } from "../../models/tracking-data-model.types";
import type { UserModel } from "@/contexts/user/models/user.model";
import type { SelfDescribingJson } from "@snowplow/browser-tracker";

export function snowplowCommmonPayload(
  trackingData: TrackingDataModel,
  jpmuid: string,
  user: UserModel,
) {
  const pageViewPayload = buildPageViewPayload(trackingData);
  const userPayload = buildUserPayload(user, jpmuid);
  const accessAgreementPayload = buildAccessAgreementPayload(user);
  return [userPayload, pageViewPayload, accessAgreementPayload].filter(
    (payload): payload is SelfDescribingJson => payload !== null,
  );
}

export function buildPageViewPayload(
  trackingData: TrackingDataModel,
): null | SelfDescribingJson {
  return shouldBuildPageViewContext(trackingData.cms)
    ? {
        data: {
          content_id: trackingData.contentId,
          content_type: trackingData.contentType,
          editorial_category: "NOTSET",
          page_name: trackingData.pageName,
          page_restricted: trackingData.pageRestricted,
          page_restricted_type: trackingData.pageRestrictedType,
          page_type: trackingData.pageType,
          section_id: trackingData.sectionId,
          section_name: trackingData.sectionName,
          section_path_id: [],
          site: trackingData.site,
          sub_site: "NOTSET",
        },
        schema: "iglu:dk.jyllands-posten/page_view/jsonschema/2-0-1",
      }
    : null;
}

function shouldBuildPageViewContext(cms: null | string) {
  return cms !== "kforum";
}

export function buildUserPayload(
  user: UserModel,
  jpmuid: string,
): SelfDescribingJson {
  return {
    data: {
      ab_group: 0,
      anon_id: jpmuid,
      corp_id: "NOTSET",
      // We are not using this but have to adhere to the JP Snowplow schema
      grp_authenticated: user.access.$class === "ip" ? "yes" : "no",

      grp_authorized: user.access.$class === "ip" ? "yes" : "no",

      user_authenticated: user.loggedIn ? "yes" : "no",
      user_authorized: user.hasAccess ? "yes" : "no",
      user_id: user.user?.ssoId ?? "anon",
    },
    schema: "iglu:dk.jyllands-posten/user/jsonschema/2-0-3",
  };
}

function buildAccessAgreementPayload(
  user: UserModel,
): null | SelfDescribingJson {
  const accessAgreements = user.accessAgreements
    .filter(
      (accessAgreement) =>
        accessAgreement.accessAgreementId !== null &&
        accessAgreement.accountNumber !== null &&
        accessAgreement.accountNumber.trim() !== "",
    )
    .map((accessAgreement) => ({
      account_number: accessAgreement.accountNumber!.trim(), // eslint-disable-line @typescript-eslint/no-non-null-assertion -- We've already checked this.
      id: accessAgreement.accessAgreementId!.toString(), // eslint-disable-line @typescript-eslint/no-non-null-assertion -- We've already checked this.
    }));
  return accessAgreements.length > 0
    ? {
        data: {
          access_agreements: accessAgreements,
        },
        schema: "iglu:dk.jyllands-posten/access_agreements/jsonschema/1-0-0",
      }
    : null;
}
