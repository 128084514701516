"use client";

import { useSite } from "@/contexts/site/site.context";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import type { GemiusParams } from "gemius/prism";
import { gemius_hit, gemius_init } from "gemius/prism";
import { useEffect, useState } from "react";

export function useGemiusTracking() {
  const [isTracked, setIsTracked] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const id = useSite().gemiusId;
  const consent = useCookiebotConsent();

  useEffect(() => {
    const abortController = new AbortController();
    if (id === null || isTracked) {
      return;
    }

    if (typeof gemius_hitcollector !== "undefined" && !isInitialized) {
      setIsInitialized(true);
      return;
    }
    const sendTrack = () => {
      if (isInitialized) {
        setIsTracked(true);
        gemius_hit(id);
        return;
      }
      setIsTracked(true);
      setIsInitialized(true);
      const params: GemiusParams = {
        cmp_timeout: Number.POSITIVE_INFINITY,
        hitcollector: "https://gadk.hit.gemius.pl",
        identifier: id,
        use_cmp: true,
      };
      gemius_init(params, "https://gadk.hit.gemius.pl/xgemius.js");
    };

    sendTrack();

    return () => {
      abortController.abort();
    };
  }, [isInitialized, consent]);
}
