"use client";

import { TrackPageView } from "./TrackPageView.component";
import * as Sentry from "@sentry/nextjs";
import { usePathname, useSearchParams } from "next/navigation";
import { useState, useEffect } from "react";
type TrackPageViewContainerProps = {
  readonly referrer: null | string;
};
export function TrackPageViewContainer({
  referrer
}: TrackPageViewContainerProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [absoluteUrl, setAbsoluteUrl] = useState<null | string>(null);
  useEffect(() => {
    const origin = window.location.origin;
    const query = searchParams.size > 0 ? `?${searchParams.toString()}` : "";
    if (/.*ez-.*/u.exec(origin) ?? /.*proxy.*/u.exec(origin)) {
      const replay = Sentry.getReplay();
      if (replay !== undefined && replay.getRecordingMode() !== "session") {
        replay.getRecordingMode();
        replay.start();
      }
    }
    setAbsoluteUrl(`${origin}${pathname}${query}`);
  }, [pathname, searchParams]);
  return absoluteUrl !== null ? <TrackPageView currentURL={encodeURIComponent(absoluteUrl)} key="archive" referrer={referrer} data-sentry-element="TrackPageView" data-sentry-component="TrackPageViewContainer" data-sentry-source-file="TrackPageViewContainer.component.tsx" /> : null;
}