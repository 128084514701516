"use client";

import type { UserModel } from "./models/user.model";
import type { SiteDto } from "@/services/api/content/dtos/shared/site-dto.types";
import { usePathname, useSearchParams } from "next/navigation";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
const UserContext = createContext<undefined | UseUser>(undefined);
type UseUser = {
  readonly isLoggedIn: boolean;
  readonly urls: {
    readonly createLoginUrl: (redirectTo: string) => string;
    readonly createLogoutUrl: (url: string) => string;
    readonly loginAndReturnUrl: string;
    readonly logoutAndReturnUrl: string;
    readonly manageAccount: string;
  };
  readonly model: UserModel;
};
export function useUser(): UseUser {
  const user = useContext(UserContext);
  if (user === undefined) {
    throw new Error("An UserProvider must be present in the component hierarchy.");
  }
  return user;
}
type UserProviderProps = {
  readonly user: UserModel;
  readonly children: ReactNode;
  readonly siteDto: Pick<SiteDto, "links">;
};
export const createLoginUrl = (redirectTo: string) => `/auth/login?redirectTo=${encodeURIComponent(redirectTo)}`;
export const createLogoutUrl = (url: string) => `/auth/logout?url=${encodeURIComponent(url)}`;
export function UserProvider({
  children,
  siteDto,
  user
}: UserProviderProps) {
  const [loginAndReturnUrl, setLoginAndReturnUrl] = useState("/auth/login");
  const [logoutAndReturnUrl, setLogoutAndReturnUrl] = useState("/auth/logout");
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    if (typeof window !== "undefined") {
      // We might be able to pass down the domain instead
      const origin = window.location.origin;
      const query = searchParams.size > 0 ? `?${searchParams.toString()}` : "";
      const redirectUrl = `${origin}${pathname}${query}`;
      setLoginAndReturnUrl(createLoginUrl(redirectUrl));
      setLogoutAndReturnUrl(createLogoutUrl(redirectUrl));
    }
  }, [pathname, searchParams]);
  const provision = useMemo<UseUser>(() => ({
    isLoggedIn: user.loggedIn,
    model: user,
    urls: {
      createLoginUrl,
      createLogoutUrl,
      loginAndReturnUrl,
      logoutAndReturnUrl,
      manageAccount: siteDto.links.myAccount.url
    }
  }), [user, loginAndReturnUrl, logoutAndReturnUrl, siteDto.links.myAccount.url]);
  return <UserContext.Provider value={provision} data-sentry-element="unknown" data-sentry-component="UserProvider" data-sentry-source-file="user.context.tsx">{children}</UserContext.Provider>;
}